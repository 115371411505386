define("discourse/plugins/discourse-private-topics/discourse/connectors/category-custom-security/private-topics", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let PrivateTopics = _exports.default = (_dec = (0, _object.computed)("site.groups.[]"), (_class = class PrivateTopics extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "selectedGroups", _descriptor3, this);
      let groupNames = [];
      let groupIds = (this.args.outletArgs.category.custom_fields.private_topics_allowed_groups || "").split(",").filter(Boolean).map(id => parseInt(id, 10));
      this.site.groups.forEach(group => {
        if (groupIds.includes(parseInt(group.id, 10))) {
          groupNames.push(group.name);
        }
      });
      this.selectedGroups = groupNames;
    }
    get availableGroups() {
      return (this.site.groups || []).map(g => {
        // don't list "everyone"
        return g.id === 0 ? null : g.name;
      }).filter(Boolean);
    }
    onChangeGroups(groupNames) {
      this.selectedGroups = groupNames;
      // let groupNames = values.split(",").filter(Boolean);
      let groupIds = [];
      this.site.groups.forEach(group => {
        if (groupNames.includes(group.name)) {
          groupIds.push(group.id);
        }
      });
      this.args.outletArgs.category.custom_fields.private_topics_allowed_groups = groupIds.join(',');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "availableGroups", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "availableGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeGroups", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeGroups"), _class.prototype)), _class));
  ;
});